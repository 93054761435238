require("@rails/ujs").start()


//--- Bootstrap
import 'bootstrap';

import appInit from './angle/app.init';
document.addEventListener('DOMContentLoaded', appInit);

import './common'
import './accounts'
import './channel_tags/wizard'
import './channel_tags/wizard_target_rules'
import './channel_tags/batch'
import './channel_tags'
import './feed_data_services'
import './feed_traffic_reports'
import './global_settings'
import './protected_ip_limits'
import './invoices'
import './publisher_traffic_reports'
import './traffic_limits'
import './traffic_filler'
import './confirm'
import './publisher_reports'
import './profits'
import './feeds'
import './blend'
