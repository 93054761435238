import $ from "jquery";
import { ujsGet } from "./ujs_helpers";

$('body').on('change', '.feed_select', function (e) {
  if (!$(this).val()) return
  var blendId = $(this).data("blend-id")

  $("#feed_tag_select").attr("disabled", false)
  $("#prefix-field").val($(this).find("option:selected").data("prefix"))

  ujsGet(
    `/blends/${blendId}/feed_tags/available`,
    { feed_id: $(this).val(), dropdown_id: "feed_tag_select" }
  )
  $('#feed_tag_select').select2({
    theme: 'bootstrap4',
    width: 'resolve'
  });
})

$('body').on('change', '.create-new-rule-feed-tag', function (e) {
  if ($(this).is(':checked')) {
    $('#new-feed-tag').removeClass('hidden');
    $('#exists-feed-tag').addClass('hidden');
  } else {
    $('#new-feed-tag').addClass('hidden');
    $('#exists-feed-tag').removeClass('hidden');
  }
})
