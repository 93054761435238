// Table Check All
// -----------------------------------

import $ from 'jquery';

function initTableCheckAll() {

    $('[data-check-all]').on('change', function() {
        var $this = $(this),
            index = $this.index() + 1,
            checkbox = $this.find('input[type="checkbox"]'),
            table = $this.parents('table');
        // Make sure to affect only the correct checkbox column
        table.find('tbody > tr > td:nth-child(' + index + ') input[type="checkbox"]')
            .prop('checked', checkbox[0].checked);

    });

}

export default initTableCheckAll;