import $ from "jquery";

$('body').on('click', '.add-url-param', function (e) {
  e.preventDefault();
  const formName = $(this).data('formName');
  const wrapper = $(`.${formName}-url-params-wrapper`);

  if (wrapper.children().length < 20) {
    addTargetRuleUrlParams(formName, '', '');
  }
});

const addTargetRuleUrlParams = (formName, fieldName, fieldValue) => {
  var template = $(`.${formName}-template`).html();
  var wrapper = $(`.${formName}-url-params-wrapper`);
  template = template.replace(/FIELD_ID/g, `${wrapper.children().length}`);
  template = template.replace(/FIELD_NAME/g, fieldName);
  template = template.replace(/FIELD_VALUE/g, fieldValue);

  wrapper.prepend(template);
}

$('body').on('change', '.url-param-fields', function () {
  const formName = $(this).data('formName');

  rebuildBaseUrlParams(formName);
});

$('body').on('click', '.remove-url-param', function (e) {
  e.preventDefault();

  const formName = $(this).data('formName');
  $(this).closest(`.${formName}-url-params-fields`).remove();

  rebuildBaseUrlParams(formName);
});

const rebuildBaseUrlParams = (formName) => {
  const $baseUrl = $(`#form_${formName}_base_url`);
  const baseUrl = new URL($baseUrl.val());
  baseUrl.search = "";

  $(`.${formName}-url-params-fields`).get().reverse().forEach(function (element) {
    const name = $(element).find('.url-param-name').val();
    const value = $(element).find('.url-param-value').val();

    if (name.length && value.length) {
      baseUrl.searchParams.append(name, value);
    }
  });

  $baseUrl.val(baseUrl.href);
};

const extractAndRenderBaseUrlParamsFields = () => {
  $('.tag_base_url').each(function (_index, element) {
    const formName = $(element).data('formName');
    const baseUrl = new URL($(element).val());
    const params = baseUrl.searchParams;

    params.forEach((value, name) => {
      addTargetRuleUrlParams(formName, name, value);
    });
  });
}

$('body').ready(function () {
  extractAndRenderBaseUrlParamsFields();
});

$('body').on('change', '.tag_base_url', function () {
  const formName = $(this).data('formName');
  $(`.${formName}-url-params-fields`).remove();

  if (!$(this).val()) return;

  const baseUrl = new URL($(this).val());
  const params = baseUrl.searchParams;

  params.forEach((value, name) => {
    addTargetRuleUrlParams(formName, name, value);
  });
});
